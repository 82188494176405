<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-row>
        <v-col
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-flask"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-2 font-weight-light">
                {{ $t('titleProducts') }}
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="showAddProduct"
                      absolute
                      dark
                      fab
                      top
                      right
                      small
                      color="indigo"
                      v-on="on"
                      @click="onAddNewProduct"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </div>
            </template>

            <v-text-field
              v-model="product_search"
              append-icon="mdi-magnify"
              class="ml-auto"
              :label="$t('dataTableSearch')"
              hide-details
              single-line
              style="max-width: 250px;"
            />

            <v-divider class="mt-1" />

            <v-data-table
              v-model="selected"
              :headers="product_headers"
              :items="products"
              item-key="productId"
              :search.sync="product_search"
              multi-sort
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="showAddProduct"
                  small
                  depressed
                  class="ml-2"
                  color="success"
                  @click="onProductEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <!--
                <v-btn
                  v-if="showAddProduct"
                  class="ml-2"
                  outlined
                  small
                  color="error"
                  @click="onProductDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
                -->
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import productAPI from '@/services/productServices'
  import verifyAPI from '@/authentication/authorization'
  export default {
    Name: 'Products',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    data: function () {
      return {
        appTitle: this.$t('titleProducts'),
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/dashboard',
          },
          {
            text: this.$t('bcSettings'),
            disabled: true,
            href: '/settings',
          },
          {
            text: this.$t('bcProducts'),
            disabled: true,
            href: '/settings/products',
          },
        ],
        showAddProduct: false,
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
        selected: [],
        product_search: undefined,
        products: [],
        product_headers: [
          {
            text: this.$t('colProductName'),
            value: 'productName',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colProductType'),
            value: 'productTypeText',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colProductUnit'),
            value: 'unitofMeasureText',
            width: '10%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colProductStatus'),
            value: 'statusCodeText',
            width: '10%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colActions'),
            value: 'actions',
            width: '10%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
        ],
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
    },
    mounted: function () {
      this.modelProcessing = true
      if (verifyAPI.isAuthorized(this.currentUser, 2)) {
        this.showAddProduct = true
      }
      productAPI.getAllProducts()
        .then(response => {
          //  console.log(response)
          this.products = [...this.products, ...response.data]
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
    },
    methods: {
      onAddNewProduct: function () {
        this.$router.push('ProductDetails/0')
      },
      onProductEdit: function (item) {
        this.$router.push({
          path: `ProductDetails/${item.productId}`,
        })
      },
      onProductDelete: function (item) {
        const msg = `${this.$t('confirmProductDelete')} ${item.productName}\n${this.$t('confirmContinue')}`
        if (confirm(msg)) {
          this.modelProcessing = true
          productAPI.deleteProduct(item.productId)
            .then(repsonse => {
              alert(this.$t('alertProductDelete'))
              productAPI.getAllProducts()
                .then(response => {
                  //  console.log(response)
                  this.products = response.data
                  this.modelProcessing = false
                })
                .catch(error => {
                  this.showErrorDialog = true
                  this.errorMessage = error.message
                  this.modelProcessing = false
                })
            })
            .catch(error => {
              this.modelProcessing = false
              this.errorMessage = error.errorMessage
              this.showErrorDialog = true
            })
        }
      },
    },
  }
</script>
